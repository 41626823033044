import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromFeatureFlags from './feature-flags';

@Component({
  selector: 'squadbox-playground',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.UseSocialIconDirective,
    fromSeriousSystem.UseLogoDirective,
    fromSeriousSystem.AvatarDirective,
    fromSeriousSystem.UseNavigationIconDirective,
    fromSeriousSystem.FileChipComponent,
    fromSeriousSystem.NavSectionDirective,
    fromSeriousSystem.MessageComponent,
    fromSeriousSystem.DropdownMenuComponent,
    fromSeriousSystem.InputComponent,
    fromSeriousSystem.PromptInputComponent,
    fromSeriousSystem.TextareaComponent,
    fromFeatureFlags.FeatureFlagsDirective,
    TranslateModule,
  ],
  template: `
    <div class="flex flex-col gap-8 m-8">
      <div>
        <h1 class="typo-h4">Colors - Neutral</h1>
        <div
          class="flex flex-row gap-0.5 *:flex *:flex-col *:size-auto *:p-1 *:pt-2.5 *:justify-end *:items-center font-semibold"
        >
          <div class="bg-neutral-50">
            <span> 50 </span>
          </div>
          <div class="bg-neutral-100">
            <span> 100 </span>
          </div>
          <div class="bg-neutral-200">
            <span> 200 </span>
          </div>
          <div class="bg-neutral-300">
            <span> 300 </span>
          </div>
          <div class="bg-neutral-400">
            <span> 400 </span>
          </div>
          <div class="bg-neutral-500 text-neutral-50">
            <span> 500 </span>
          </div>
          <div class="bg-neutral-600 text-neutral-50">
            <span> 600 </span>
          </div>
          <div class="bg-neutral-700 text-neutral-50">
            <span> 700 </span>
          </div>
          <div class="bg-neutral-800 text-neutral-50">
            <span> 800 </span>
          </div>
          <div class="bg-neutral-900 text-neutral-50">
            <span> 900 </span>
          </div>
        </div>
      </div>
      <div>
        <h1 class="typo-h4">Colors - Primary</h1>
        <div
          class="flex flex-row gap-0.5 *:flex *:flex-col *:size-auto *:p-1 *:pt-2.5 *:justify-end *:items-center font-semibold"
        >
          <div class="bg-primary-50">
            <span> 50 </span>
          </div>
          <div class="bg-primary-100">
            <span> 100 </span>
          </div>
          <div class="bg-primary-200">
            <span> 200 </span>
          </div>
          <div class="bg-primary-300">
            <span> 300 </span>
          </div>
          <div class="bg-primary-400">
            <span> 400 </span>
          </div>
          <div class="bg-primary-500 text-neutral-50">
            <span> 500 </span>
          </div>
          <div class="bg-primary-600 text-neutral-50">
            <span> 600 </span>
          </div>
          <div class="bg-primary-700 text-neutral-50">
            <span> 700 </span>
          </div>
          <div class="bg-primary-800 text-neutral-50">
            <span> 800 </span>
          </div>
          <div class="bg-primary-900 text-neutral-50">
            <span> 900 </span>
          </div>
        </div>
      </div>
      <div>
        <h1 class="typo-h4">Colors - Secondary</h1>
        <div
          class="flex flex-row gap-0.5 *:flex *:flex-col *:size-auto *:p-1 *:pt-2.5 *:justify-end *:items-center font-semibold"
        >
          <div class="bg-secondary-50">
            <span> 50 </span>
          </div>
          <div class="bg-secondary-100">
            <span> 100 </span>
          </div>
          <div class="bg-secondary-200">
            <span> 200 </span>
          </div>
          <div class="bg-secondary-300">
            <span> 300 </span>
          </div>
          <div class="bg-secondary-400">
            <span> 400 </span>
          </div>
          <div class="bg-secondary-500 text-neutral-50">
            <span> 500 </span>
          </div>
          <div class="bg-secondary-600 text-neutral-50">
            <span> 600 </span>
          </div>
          <div class="bg-secondary-700 text-neutral-50">
            <span> 700 </span>
          </div>
          <div class="bg-secondary-800 text-neutral-50">
            <span> 800 </span>
          </div>
          <div class="bg-secondary-900 text-neutral-50">
            <span> 900 </span>
          </div>
        </div>
      </div>
      <div>
        <h1 class="typo-h4">Colors - Tertiary</h1>
        <div
          class="flex flex-row gap-0.5 *:flex *:flex-col *:size-auto *:p-1 *:pt-2.5 *:justify-end *:items-center font-semibold"
        >
          <div class="bg-tertiary-50">
            <span> 50 </span>
          </div>
          <div class="bg-tertiary-100">
            <span> 100 </span>
          </div>
          <div class="bg-tertiary-200">
            <span> 200 </span>
          </div>
          <div class="bg-tertiary-300">
            <span> 300 </span>
          </div>
          <div class="bg-tertiary-400">
            <span> 400 </span>
          </div>
          <div class="bg-tertiary-500 text-neutral-50">
            <span> 500 </span>
          </div>
          <div class="bg-tertiary-600 text-neutral-50">
            <span> 600 </span>
          </div>
          <div class="bg-tertiary-700 text-neutral-50">
            <span> 700 </span>
          </div>
          <div class="bg-tertiary-800 text-neutral-50">
            <span> 800 </span>
          </div>
          <div class="bg-tertiary-900 text-neutral-50">
            <span> 900 </span>
          </div>
        </div>
      </div>
      <div>
        <h1 class="typo-h4">Colors - Gradients</h1>
        <div
          class="flex flex-row gap-2 *:flex *:flex-col *:size-auto *:p-6 *:pt-2.5 *:justify-end *:items-center font-semibold text-neutral-50"
        >
          <div class="bg-gradient-a-horizontal">
            <span> A </span>
            <span class="typo-caption"> Horizontal </span>
          </div>
          <div class="bg-gradient-a-diagonal">
            <span> A </span>
            <span class="typo-caption"> Diagonal </span>
          </div>
          <div class="bg-gradient-b-horizontal">
            <span> B </span>
            <span class="typo-caption"> Horizontal </span>
          </div>
          <div class="bg-gradient-b-diagonal">
            <span> B </span>
            <span class="typo-caption"> Diagonal </span>
          </div>
          <div class="bg-gradient-c-horizontal">
            <span> C </span>
            <span class="typo-caption"> Horizontal </span>
          </div>
          <div class="bg-gradient-c-diagonal">
            <span> C </span>
            <span class="typo-caption"> Diagonal </span>
          </div>
          <div class="bg-gradient-d-horizontal">
            <span> D </span>
            <span class="typo-caption"> Horizontal </span>
          </div>
          <div class="bg-gradient-d-diagonal">
            <span> D </span>
            <span class="typo-caption"> Diagonal </span>
          </div>
        </div>
      </div>

      <div class="flex flex-row gap-3 *:size-60">
        <img style="height: 150px" sdUseLogo="logo-full-filled" />
        <img style="height: 150px" sdUseLogo="logo-full" />
        <img style="height: 150px" sdUseLogo="logo-minimal-filled" />
        <img style="height: 150px" sdUseLogo="logo-minimal" />
      </div>
      <div class="flex flex-col gap-2">
        <h1 class="typo-h4">Button Variants</h1>
        <div class="flex gap-2">
          <button sdButton variant="filled" color="primary">Button</button>
          <button sdButton variant="filled" color="primary" disabled>
            Button
          </button>
        </div>
        <div class="flex gap-2">
          <button sdButton variant="filled" color="neutral">Button</button>
          <button sdButton variant="filled" color="neutral" disabled>
            Button
          </button>
        </div>
        <div class="flex gap-2">
          <button sdButton variant="tonal" color="primary">Button</button>
          <button sdButton variant="tonal" color="primary" disabled>
            Button
          </button>
        </div>
        <div class="flex gap-2">
          <button sdButton variant="tonal" color="neutral">Button</button>
          <button sdButton variant="tonal" color="neutral" disabled>
            Button
          </button>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <h1 class="typo-h4">Button Variants with Icons</h1>
        <div class="flex gap-2">
          <button sdButton variant="filled" color="primary">
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
          <button sdButton variant="filled" color="primary" disabled>
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
        </div>
        <div class="flex gap-2">
          <button sdButton variant="filled" color="neutral">
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
          <button sdButton variant="filled" color="neutral" disabled>
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
        </div>
        <div class="flex gap-2">
          <button sdButton variant="tonal" color="primary">
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
          <button sdButton variant="tonal" color="primary" disabled>
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
        </div>
        <div class="flex gap-2">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseUIBasicIcon="plus"></svg>
            Button
          </button>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <h1 class="typo-h4">Button with Social Icons</h1>

        <div class="flex gap-2 *:w-[400px]">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseSocialIcon="microsoft-square"></svg>
            Continue with Microsoft
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseSocialIcon="microsoft-square"></svg>
            Continue with Microsoft
          </button>
        </div>
        <div class="flex gap-2 *:w-[400px]">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseSocialIcon="facebook-circle"></svg>
            Continue with Facebook
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseSocialIcon="facebook-circle"></svg>
            Continue with Facebook
          </button>
        </div>
        <div class="flex gap-2 *:w-[400px]">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseSocialIcon="apple-circle"></svg>
            Continue with Apple
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseSocialIcon="apple-circle"></svg>
            Continue with Apple
          </button>
        </div>
        <div class="flex gap-2 *:w-[400px]">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseSocialIcon="instagram-circle"></svg>
            Continue with Instagram
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseSocialIcon="instagram-circle"></svg>
            Continue with Instagram
          </button>
        </div>
        <div class="flex gap-2 *:w-[400px]">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseSocialIcon="indeed-no-bg"></svg>
            Continue with Indeed
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseSocialIcon="indeed-no-bg"></svg>
            Continue with Indeed
          </button>
        </div>
        <div class="flex gap-2 *:w-[400px]">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseSocialIcon="youtube-circle"></svg>
            Continue with Youtube
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseSocialIcon="youtube-circle"></svg>
            Continue with Youtube
          </button>
        </div>
        <div class="flex gap-2 *:w-[400px]">
          <button sdButton variant="tonal" color="neutral">
            <svg sdUseSocialIcon="tiktok-no-bg"></svg>
            Continue with Tiktok
          </button>
          <button sdButton variant="tonal" color="neutral" disabled>
            <svg sdUseSocialIcon="tiktok-no-bg"></svg>
            Continue with Tiktok
          </button>
        </div>
      </div>

      <div>
        <h1 class="typo-h4">Chip</h1>
        <div class="flex gap-2 pt-2.5">
          <sd-file-chip title="Convention.pdf"></sd-file-chip>
          <sd-file-chip
            title="Convention Collective HalfSerious.docx"
            fileType="word"
          ></sd-file-chip>
        </div>
      </div>

      <div>
        <h1 class="typo-h4">Nav Sections</h1>
        <div
          class="flex flex-row gap-2 *:pt-2.5 *:justify-end *:items-center font-semibold"
        >
          <div class="bg-gradient-b-diagonal flex flex-row space-x-2 p-6">
            <div sdNavSection status="selected">
              <svg sdUseNavigationIcon="home-filled"></svg>
            </div>
            <div sdNavSection>
              <svg sdUseNavigationIcon="home"></svg>
            </div>
          </div>

          <div
            class="bg-gradient-b-diagonal flex-col w-[440px] space-y-2 py-6 px-2"
          >
            <div sdNavSection size="expanded" status="selected">
              <svg sdUseNavigationIcon="home-filled"></svg>
              <span>{{ 'HOME.TITLE' | translate }}</span>
            </div>
            <div sdNavSection size="expanded">
              <svg sdUseNavigationIcon="home"></svg>
              <span>{{ 'HOME.TITLE' | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h1 class="typo-h4">Messages</h1>
        <div class="flex flex-row gap-2 *:pt-2.5 *:justify-end *:items-center">
          <sd-message
            message="Can you help me create a personalized morning routine that would help increase my productivity throughout the day? Start by asking me about my current habits and what activities energize me in the morning."
            [isFromUser]="false"
          ></sd-message>
          <sd-message message="Hello!" [isFromUser]="false"></sd-message>
        </div>
        <div class="flex flex-row gap-2 *:pt-2.5 *:justify-end *:items-center">
          <sd-message
            message="Can you help me create a personalized morning routine that would help increase my productivity throughout the day? Start by asking me about my current habits and what activities energize me in the morning."
            [isFromUser]="true"
          ></sd-message>
          <sd-message message="Hello!" [isFromUser]="true"></sd-message>
        </div>
        <div class="flex flex-row gap-2 *:pt-2.5 *:justify-end *:items-center">
          <sd-message [isFromUser]="false" [isThinking]="true"></sd-message>
        </div>
        <sd-message [message]="markdown"></sd-message>
      </div>
      <div class="flex flex-col gap-8">
        <h1 class="typo-h4">Overlay elements</h1>
        <sd-dropdown-menu
          [isDropdownMenuOpened]="true"
          [options]="[
            {
              label: 'Save Assistant',
              icon: 'save',
              action: 'option1',
              disabled: true
            },
            { label: 'Rename', icon: 'edit', action: 'option2' },
            { label: 'Archive', icon: 'archive', action: 'option2' },
            { label: 'Delete', icon: 'delete', action: 'option3', error: true }
          ]"
        ></sd-dropdown-menu>
      </div>
      <div class="flex flex-col">
        <h1 class="typo-h4">Inputs and textarea</h1>
        <sd-prompt-input class="mt-4"> </sd-prompt-input>
        <form [formGroup]="form" class="w-full flex flex-col gap-2">
          <h1 class="typo-h5 mt-4">Inputs</h1>
          <div class="flex flex-row gap-2">
            <sd-input formControlName="input"></sd-input>
            <sd-input formControlName="inputDisabled"></sd-input>
          </div>
          <h1 class="typo-h5 mt-4">Textarea</h1>
          <div class="flex flex-row gap-10">
            <sd-textarea
              formControlName="textarea"
              [limit]="20"
              supportingText="Supporting text"
            ></sd-textarea>
            <sd-textarea
              formControlName="textarea"
              supportingText="Supporting text"
            ></sd-textarea>
            <sd-textarea formControlName="textarea" [limit]="20"></sd-textarea>
            <sd-textarea formControlName="textareaDisabled"></sd-textarea>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.None,
})
export class PlaygroundPage implements OnInit {
  form = new FormGroup({
    input: new FormControl('Sweet potato'),
    inputDisabled: new FormControl({
      value: '',
      disabled: true,
    }),
    textarea: new FormControl('Sweet potato'),
    textareaDisabled: new FormControl({
      value: '',
      disabled: true,
    }),
  });

  ngOnInit() {
    this.form.valueChanges.subscribe((value) => {
      // eslint-disable-next-line no-console
      console.log('Input value changed:', value);
    });
  }

  /* spell-checker: disable */
  public readonly markdown = `
    # Titre de niveau 1

    ## Titre de niveau 2

    ### Titre de niveau 3

    #### Titre de niveau 4

    ##### Titre de niveau 5

    ###### Titre de niveau 6

    ---

    ## Texte et mise en forme

    Ceci est un paragraphe simple.

    **Ceci est du texte en gras.**

    *Ceci est du texte en italique.*

    ***Ceci est du texte en gras et italique.***

    ~~Ceci est du texte barré.~~

    > Ceci est une citation.

    ## Listes

    ### Liste à puces

    - Élément 1
    - Élément 2
      - Sous-élément 2.1
      - Sous-élément 2.2
    - Élément 3

    ### Liste numérotée

    1. Premier élément
    2. Deuxième élément
      1. Sous-élément 2.1
      2. Sous-élément 2.2
    3. Troisième élément

    ## Liens et images

    [Ce lien mène à Google](https://www.google.com)

    ![Texte alternatif pour l'image](https://via.placeholder.com/150)

    ## Code

    ### Code inline

    Voici un exemple de \`code inline\`.

    ### Bloc de code

    \`\`\`javascript
    function greet(name) {
        console.log("Hello, " + name + "!");
    }
    greet("world");
    \`\`\`

    ### Bloc de code avec spécification de la langue

    \`\`\`python
    def hello_world():
        print("Hello, World!")
    \`\`\`

    ## Tableaux

    | Colonne 1 | Colonne 2 | Colonne 3 |
    |-----------|-----------|-----------|
    | Ligne 1   | Donnée 1  | Donnée 2  |
    | Ligne 2   | Donnée 3  | Donnée 4  |
    | Ligne 3   | Donnée 5  | Donnée 6  |

    ## Tâches

    - [x] Tâche 1 complétée
    - [ ] Tâche 2 non complétée
    - [ ] Tâche 3 non complétée

    ## Éléments divers

    Voici un mot en **gras** au milieu d'une phrase.

    Voici un [lien en contexte](https://www.example.com).

    ---

    ## Éléments HTML intégrés

    <p>Ceci est un paragraphe écrit en HTML.</p>

    <div style="color: blue; text-align: center;">
      <strong>Ceci est du texte bleu et centré.</strong>
    </div>

    ---

    ## Cas spéciaux

    Voici une ligne avec des \`backticks\` (\`\` \` \`\`).

    Voici un texte avec des [crochets] et des {accolades}.

    ---

    ## Mentions et références

    @utilisateur : Mention d'un utilisateur fictif.

    Référence de pied de page[^1].

    [^1]: Ceci est un pied de page.

    ---

    ## Commentaires

    <!-- Ceci est un commentaire en Markdown, il ne sera pas rendu dans l'affichage final. -->
`;
}
